<template>
	<page-container class="index" :page="page">
		<heading-image type="large">
			<div v-if="page.vimeoID">
				<div style="padding: 56.25% 0 0; position: relative">
					<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
						<p class="hero-title">
							{{ defaults[locale].homepage.headingImageTitle }}
						</p>
						<div v-parse-links v-html="defaults[locale].homepage.headingImageContent" />
					</div>
					<iframe
						:src="
							'https://player.vimeo.com/video/' +
							page.vimeoID +
							'?h=48e73401ce&amp;loop=1&amp;muted=1&amp;badge=0&amp;autoplay=1&amp;controls=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
						"
						class="subpage-iframe"
						frameborder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						allowfullscreen
						style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
						title="Door 74"
					/>
				</div>
			</div>
			<div v-else>
				<hero-image v-if="headingImage">
					<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
						<p class="hero-title">
							{{ defaults[locale].homepage.headingImageTitle }}
						</p>
						<div v-parse-links v-html="defaults[locale].homepage.headingImageContent" />
					</div>
					<picture>
						<source
							v-if="headingImage.imagePortraitWebp"
							:srcset="headingImage.imagePortraitWebp"
							media="(max-width: 500px)"
							type="image/webp"
						/>
						<source
							v-if="headingImage.imagePortrait"
							:srcset="headingImage.imagePortrait"
							media="(max-width: 500px)"
						/>
						<source :srcset="headingImage.imageWebp" type="image/webp" />
						<source :srcset="headingImage.image" />
						<img :src="headingImage.image" :alt="headingImage.imageAlt" />
					</picture>
				</hero-image>
			</div>
		</heading-image>

		<main-content>
			<div class="main-intro">
				<div class="intro-image">
					<picture>
						<source :srcset="defaults[locale].homepage.introImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].homepage.introImage" />
						<img
							:src="defaults[locale].homepage.introImage"
							:alt="defaults[locale].homepage.introImageAlt"
							loading="lazy"
						/>
					</picture>
				</div>
				<div class="intro-text">
					<h2 class="subtitle">
						{{ page.subtitle }}
					</h2>
					<h1>{{ page.title }}</h1>
					<div v-parse-links v-html="page.content" />
				</div>
			</div>
		</main-content>

		<contentblocks :items="page.contentblocks" class="section-contentblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2 class="subtitle">
							{{ defaults[locale].homepage.sectionContentblocksSubtitle }}
						</h2>
						<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
					</div>
				</div>
			</template>
		</contentblocks>

		<section class="snapwidget">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						<a href="https://www.instagram.com/door_74/" target="_blank"> @door_74 </a>
					</h2>
					<h2 style="text-transform: uppercase">Come hang out with us</h2>
				</div>
				<div class="columns column12">
					<iframe
						src="https://snapwidget.com/embed/1034296%22"
						class="snapwidget-widget"
						allowtransparency="true"
						frameborder="0"
						scrolling="no"
						style="border: none; overflow: hidden; width: 100%"
					/>
				</div>
			</div>
		</section>

		<!--
		<section v-if="defaults[locale].homepage.contactTitle" class="content-section section-contact contact-info">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.contactSubtitle }}
					</h2>
					<h2>
						{{ defaults[locale].homepage.contactTitle }}
					</h2>
					<div v-parse-links v-html="defaults[locale].homepage.contactContent" />
				</div>
			</div>
			<div class="row bg-row">
				<div class="columns column4">
					<h3>{{ defaults[locale].website.restaurantName }}</h3>
					<p>
						{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}<br />
						{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}<br />
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon class="icon" icon="fal fa-phone" size="1x" />
							{{ defaults[locale].website.phone }} </a
						><br />
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon class="icon" icon="fal fa-envelope" size="1x" />
							{{ defaults[locale].website.email }}
						</a>
					</p>
					<div class="socials">
						<a
							v-for="medium in socials"
							:key="medium.ID"
							:class="medium.platform"
							:href="medium.url"
							:aria-label="medium.platform"
							target="_blank"
						>
							<font-awesome-icon
								v-if="!['tripadvisor', 'zoover'].includes(medium.platform)"
								:icon="`fa-brands fa-${medium.platform}`"
								size="1x"
							/>
						</a>
					</div>
				</div>
				<div class="columns column8">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2436.2468504629187!2d4.891021476984775!3d52.36594384762365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c609eab07609b3%3A0x1d902f3e4fad53f1!2sDoor%2074!5e0!3m2!1snl!2snl!4v1683626232684!5m2!1snl!2snl"
						width="100%"
						height="450"
						frameborder="0"
						style="border: 0"
						allowfullscreen=""
						aria-hidden="false"
						tabindex="0"
					/>
				</div>
			</div>
		</section>
		-->

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>

		<!--
		<section class="also-visit">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 v-if="defaults[locale].homepage.alsoVisitSubtitle" class="subtitle">
						{{ defaults[locale].homepage.alsoVisitSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.alsoVisitHeader }}</h2>
				</div>
				<div class="columns column12 align-center logo-row">
					<a href="https://cafepanache.nl/" target="_blank">
						<img src="~/assets/images/logo-cafepanache.png" loading="lazy" alt="Cafe Panache" />
					</a>
					<a href="https://cafeverlan.com/" target="_blank">
						<img src="~/assets/images/logo-verlan.png" loading="lazy" alt="Verlan" />
					</a>
				</div>
			</div>
		</section>
		-->
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

useHead({
	script: [
		{
			src: 'https://snapwidget.com/js/snapwidget.js',
		},
	],
});
</script>

<style lang="scss" scoped>
.snapwidget {
	background: $primary-background-color;
	padding: 75px 0;

	h2 {
		margin: 0 auto 40px;
		color: $contentblock-color-three;

		&.subtitle {
			margin: 0 auto 10px;
		}

		a {
			color: $contentblock-color-three;
		}
	}
}

.hero-content {
	position: absolute;
	top: calc(50% - 60px);
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	color: #fff;
	max-width: 880px;
	text-align: center;
	z-index: 5;
	left: 20px;
	right: 20px;
	margin: 0 auto;
	transform: translateY(calc(-50% + 100px));
	animation: fade-from-bottom 1.5s ease;

	.hero-title {
		width: 100%;
		font-weight: 400;
		color: $cta-color;
		font-size: 56px;
		line-height: normal;
		font-family: $heading-font-family;
		margin: 0 auto 10px;
		padding: 0;
	}

	:deep(> *) {
		margin: 0 auto 10px;
		color: #fff;
		padding: 0 40px;
		font-size: 20px;
		line-height: 24px;
	}
}

.also-visit {
	background: $primary-background-color;
	padding: 75px 0;

	h2.subtitle {
		color: $contentblock-color-three;
	}

	.logo-row {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;

		a {
			margin: 20px;
		}

		img {
			max-width: 159px;
		}
	}
}

.main-intro {
	max-width: 98%;
	margin: auto;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	align-items: center;
	position: relative;

	.intro-text {
		padding: 100px 75px;
		background: $contentblock-bg-one;
		color: $contentblock-color-one;
		width: 55%;
		position: relative;
		z-index: 2;
		margin: 125px 0 50px;

		h1,
		h2,
		h3,
		.subtitle {
			color: $contentblock-color-one;
		}
	}

	.intro-image {
		width: 50%;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		z-index: 1;

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: 2;
			background: url('~/assets/images/bg-border-intro-top.png') no-repeat top center;
			background-size: 100%;
			height: 30%;
		}

		&::after {
			background: url('~/assets/images/bg-border-intro-bottom.png') no-repeat bottom center;
			background-size: 100%;
			top: auto;
			bottom: 0;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: 1;
			position: relative;
		}
	}
}

.section-promotion {
	background: $secondary-background-color;
	margin: 0;
	padding: 200px 25px;
	overflow: auto;
	clip-path: inset(0);
	position: relative;

	&::after {
		background: rgb(0 0 0 / 60%);
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		content: '';
	}

	.row {
		position: relative;
		z-index: 10;
		color: #fff;
		overflow: hidden;
		max-width: 800px;
	}

	.promotions-image {
		position: absolute;
		object-fit: cover;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	h2:not(.subtitle) {
		color: #fff;
	}
}

.section-contentblocks {
	.blocks-header {
		margin-bottom: 70px;

		h2,
		h3 {
			color: $cta-color;

			&.subtitle {
				color: $subtitle-color;
			}
		}
	}
}

.section-contact {
	color: $contentblock-color-three;

	h2,
	h3,
	h4,
	:deep(h2),
	:deep(h3),
	:deep(h4) {
		color: $contentblock-color-three;
	}

	.column8 {
		padding: 0;
	}

	.column4 {
		padding: 10px 40px;

		h2,
		h3,
		h4,
		:deep(h2),
		:deep(h3),
		:deep(h4) {
			color: $contentblock-color-one;
		}
	}

	.subtitle {
		color: $contentblock-color-three;
	}

	.row:first-child {
		padding-bottom: 50px;
	}

	.bg-row {
		padding: 0;
		background: $contentblock-bg-one;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
		border-top: 16px solid $contentblock-bg-four;
		border-bottom: 16px solid $contentblock-bg-four;
		color: $contentblock-color-one;
		width: calc(100% - 40px);
		max-width: 1200px;

		a {
			color: $contentblock-color-one;
		}

		svg {
			color: $contentblock-color-one;
			margin: 0 5px 0 0;
			transition: color 0.3s ease-in-out;
		}

		.socials {
			a {
				color: $contentblock-color-one;
				margin: 0 10px 0 0;
				font-size: 22px;

				&:hover,
				&:hover svg {
					color: $cta-color-hover;
				}
			}
		}
	}
}

@keyframes fade-from-bottom {
	0% {
		opacity: 0;
		transform: translateY(calc(-30% + 100px));
	}

	66% {
		opacity: 0;
	}

	100% {
		opacity: 1;
		transform: translateY(calc(-50% + 100px));
	}
}

@media (max-width: 1080px) {
	.hero-content {
		max-width: 650px;
	}

	.main-intro {
		flex-flow: column wrap;

		.intro-text,
		.intro-image {
			width: 100%;
		}

		.intro-text {
			padding: 50px;
			width: 90%;
			margin: -125px auto 0;
		}

		.intro-image {
			position: relative;
		}
	}
}

@media (max-width: 900px) {
	.section-contact {
		.bg-row {
			width: 96%;
			margin: 0 auto;

			.column4,
			.column8 {
				width: 100%;
				padding: 30px;
			}
		}
	}
}

@media (max-width: 880px) {
	header.large {
		iframe {
			width: 360% !important;
			height: 130% !important;
			margin-left: -130% !important;
			min-height: 640px !important;
		}
	}

	.section-promotions {
		padding: 125px 25px;
	}

	.promotions-image {
		position: absolute;
	}

	.hero-content {
		font-size: 15px;
		padding: 0 10px;
		transform: translateY(calc(-50% + 200px));

		.hero-title {
			font-size: 30px;
		}
	}
}

@media (max-width: 640px) {
	.hero-content {
		font-size: 14px;
		padding: 0 3px;

		.hero-title {
			font-size: 24px;
		}

		:deep(p) {
			font-size: 17px;
			line-height: 24px;
		}
	}
}
</style>
